import $ from 'jquery';
import Vue from "vue";
import moment from "moment";

export default function () {

    const CallApi = new Vue({
        el: '#vue',

        data: {
            debug: false,

            call_id: 0, // уникальный идентификатор звонка
            my_leg_id: 0, // собственный айдишник абонента в звонке, по которому можно отключить себя из звонка

            inited_call: false,
            phone: '', // Номер телефона текущего звонка
            clientName: '', // Имя клиента
            duration: '', // Длительность звонка

            // Быстрый и неуправляемый перевод звонка на застройщика с пробросом номера
            // если выключен, то будет действовать управляемый перевод звонка
            // но он не поддерживает проброс номера
            quickTransferEnabled: true,

            transfer_phone: '', // Номер, на который совершается переадресация звонка
            transfer_object_name: '',
            transfer_leg_id: 0, //
            transfer_in_process: false,

            ws: null,

            display: false,
            holded: false,
            transfers: $('.call-api-transfer'),
            timerInterval: null,
            startTime: moment(),

            incomingCall: false,
        },

        created: function () {
            this.websockets();
            this.current(true);
        },

        computed: {
            phoneNumber: function () {
                return this.formatPhone(this.phone);
            },
            transferPhoneNumber: function () {
                return this.formatPhone(this.transfer_phone);
            },
            clientUrl: function () {
                return '/client/phone/?phone=' + this.phone;
            }
        },

        methods: {

            incoming: function (phone, call_id, name) {
                this.incomingCall = true;
                this.call_id = call_id;
                this.phone = String(phone);
                this.clientName = name ? name : 'Неизвестный абонент';
                this.processRequest('/call/incoming/' + this.call_id + '/' + this.phone + '/');
            },

            // Открытие главного окна звонка
            open: function (phone = '', call_id = 0, start_time = null) {

                let $this = this;

                if (this.incomingCall) {
                    this.incomingCall = false;
                }

                $this.processRequest('/call/client/' + phone + '/', {}, function (data) {
                    $this.clientName = data.clientName;
                });

                if (this.debug && call_id === 0) {
                    call_id = Math.random();
                }

                this.display = true;
                this.call_id = call_id;
                this.startTime = start_time || moment();
                this.phone = String(phone);
                this.timer();

                if (this.quickTransferEnabled) {
                    this.transfers.addClass('active');
                }
            },

            // Закрытие главного окна звонка
            close: function () {
                this.inited_call = false;

                this.unhold();

                if (this.timerInterval) {
                    clearInterval(this.timerInterval);
                }

                this.display = false;
                this.transfer_in_process = false;

                this.transfers.removeClass('active').removeClass('in-process').removeClass('disabled');
            },

            // Вызов на указанный номео
            call: function (phone) {
                let $this = this;
                $this.inited_call = true;
                this.processRequest('/call/to/' + phone + '/', {}, function (response) {
                    // Открываем панель звонка
                    $this.open(phone, response.call_session_id);
                });
            },

            // Включение режима удержания клиента
            hold: function () {
                let $this = this;
                this.processRequest('/call/hold/' + this.call_id + '/', {}, function (response) {
                    $this.holded = true;
                    $this.transfers.addClass('active');
                });
            },

            // снятие режима удержания звонка с клиентом
            unhold: function () {

                if (!this.holded || this.quickTransferEnabled) {
                    return;
                }

                this.holded = false;

                // в случае, если сейчас активен режим перевода звонка
                if (this.transfer_in_process) {

                    // То возвращаемся в разговор с клиентом
                    this.restore();

                    // Отключаем третьего абонента
                    this.disconnect(this.transfer_leg_id);

                    return;
                }

                if (!this.quickTransferEnabled) {
                    // В противном случае просто снимаем режим удержания клиента
                    let $this = this;
                    this.processRequest('/call/unhold/' + this.call_id + '/', {}, function (response) {
                        $this.transfers.removeClass('active').removeClass('in-process').removeClass('disabled');
                    });
                }
            },

            // Быстрый перевод звонка с пробросом номера клиента
            // управлять им нельзя, но мы попробуем
            quickTransfer: function (phone) {
                this.transfer_phone = String(phone);
                let $this = this;

                this.processRequest('/call/send/' + this.call_id + '/' + phone + '/', {}, function (response) {
                    $this.holded = true;
                    $this.transfer_in_process = true;
                    // Получаем информацию о звонке
                    $this.current();
                })
            },

            // Управляемый перевод звонка
            // Тут пробросить номер клиента застройщику нельзя,
            // Но мы можем управлять всей цепочкой звонка
            // Создать звонок для перевода - входим в режим перевода звонка
            transfer: function (number, callback) {
                this.transfer_phone = String(number);
                let $this = this;

                this.processRequest('/call/transfer/' + this.call_id + '/' + number + '/' + this.phone + '/', {}, function () {
                    $this.transfer_in_process = true;

                    // Получаем информацию о звонке
                    $this.current();

                    if (callback) {
                        callback($this);
                    }
                });
            },

            // Переключение клиента на третьего абонента
            switchCall: function () {
                let $this = this;
                this.processRequest('/call/switch/' + this.call_id + '/', {}, function () {
                    $this.transfer_in_process = false;
                    $this.holded = false;
                    $this.close();
                    $.notify('Клиент соединен с застройщиком', 'success');
                });
            },

            // Завершение всего звонка
            release: function () {
                let $this = this;
                $this.processRequest('/call/release/' + this.call_id + '/', {}, function () {
                    $this.transfer_in_process = false;
                    $this.close();
                });
            },

            // Отключение определенного участника
            disconnect: function (leg_id) {
                let $this = this;

                let callback = function () {
                    $this.transfer_in_process = false;

                    if (leg_id !== $this.transfer_leg_id) {
                        $this.close();
                    }
                };

                if (!leg_id) {
                    callback();
                    return;
                }

                $this.processRequest('/call/disconnect/' + this.call_id + '/' + leg_id + '/', {}, callback);
            },

            // Возврат из режима перевода звонка к разговору с клиентом
            // Третий абонент ставится на удержание
            // Клиент снимается с удержания
            restore: function () {

                if (this.quickTransferEnabled) {
                    this.transfer_in_process = false;
                    return;
                }

                let $this = this;
                $this.processRequest('/call/restore/' + this.call_id + '/', {}, function () {
                    $this.transfer_in_process = false;
                    $this.holded = false;
                    $this.transfers.removeClass('active').removeClass('in-process').removeClass('disabled');
                    $this.disconnect($this.transfer_leg_id);
                });
            },

            // Получаем список активных звонков в текущий момент
            current(initPanelOnCurrentCall = false) {

                let $this = this;

                this.processRequest('/call/list/', {}, function (response) {

                    // если в данный момент есть активные звонки
                    if (response.length) {

                        let call = response[0];

                        // Смотрим участников разговора и ищем свой номер
                        // Если находим, то значит это мы разговариваем с кем-то
                        // В этом случае показываем
                        $.each(call.legs, function (i, leg) {
                            if (leg.is_operator && leg.employee_id > 0) {
                                $this.my_leg_id = leg.leg_id;

                                // Если нужно открыть панель звонка при наличии активных звонков
                                if (initPanelOnCurrentCall && leg.state !== 'Разъединено') {
                                    // Открываем
                                    $this.startTime = moment(call.start_time);
                                    $this.open(call.contact_phone_number, call.call_session_id, $this.startTime);
                                }
                            }

                            // Если это номер третьего абонента - запоминаем его ногу, чтобы была возможность его отключить
                            if (parseInt(leg.called_phone_number) === parseInt($this.transfer_phone)) {
                                $this.transfer_leg_id = leg.leg_id;
                            }
                        });
                    } else {
                        if (!$this.debug) {
                            $this.close();
                        }
                    }
                });
            },

            timer: function () {
                let $this = this;

                if (this.timerInterval) {
                    clearInterval(this.timerInterval);
                }

                this.timePassed();
                this.timerInterval = setInterval(function () {
                    $this.timePassed();
                }, 1000);
            },

            timePassed: function () {
                let now = moment();
                let diff = now.diff(this.startTime, 'seconds');
                let diffMoment = moment.utc(diff * 1000);
                this.duration = diffMoment.format('HH:mm:ss');
            },

            websockets: function () {

                let $this = this;

                let host = document.location.hostname;
                let proto = 'https:' === document.location.protocol ? 'wss://' : 'ws://';
                let socket = proto + host + ':8000?channel=calls';
                let ws = new WebSocket(socket);

                ws.onmessage = function (event) {
                    let response = JSON.parse(event.data);

                    if (response) {

                        if (response.event === 'incoming_call') {
                            if (!$this.inited_call) {
                                $this.processRequest('/call/client/' + response.contact_phone + '/', {}, function (data) {
                                    $this.incoming(response.contact_phone, response.call_session_id, data.clientName);
                                });
                            }
                        }

                        if (response.event === 'start_of_call') {
                            $this.open(response.contact_phone, response.call_session_id);
                        }

                        if (response.event === 'end_of_call') {
                            $this.close();
                            $.notify('Звонок завершен', 'info');
                        }
                    }
                };
            },

            processRequest: function (url, data, onSuccess, onErrors) {

                if (this.debug) {
                    if (onSuccess) {
                        onSuccess.call(this, {});
                    }
                    return;
                }

                let $this = this;

                $.get(url, data, function (response) {
                    if (response.success) {
                        if (onSuccess) {
                            onSuccess.call($this, response.result);
                        }
                    } else {
                        $.each(response.errors, function (i, error) {
                            $.notify(error, 'error');
                        });
                        if (onErrors) {
                            onErrors.call($this);
                        }
                    }
                }, 'json');
            },

            formatPhone: function (phone) {
                let x = phone.replace(/\D/g, '').match(/(\d{1})(\d{3})(\d{3})(\d{4})/);
                return x ? x[1] + ' (' + x[2] + ') ' + x[3] + '-' + x[4] : phone
            }
        }
    });

    $('.call-to').on('click', function () {
        CallApi.call($(this).data('phone'));
        $('#call-api-test').show();
        return false;
    });

    $('body').on('click', '.call-api-transfer-test', function () {
        let phone = $('#call-api-test-phone').val();
        if (phone) {
            CallApi.quickTransfer(phone);
        }
        return false;
    });

    $('body').on('click', '.call-api-transfer', function () {

        if (!CallApi.quickTransferEnabled && !CallApi.holded) {
            return false;
        }

        CallApi.transfer_object_name = $(this).data('object');

        let button = $(this);
        let phone = button.data('phone');
        if (button.hasClass('test')) {
            phone = $('#call-api-test-phone').val().replace(/\D+/, '');
        }

        if (!phone || !CallApi.call_id) {
            return false;
        }

        if (CallApi.quickTransferEnabled) {
            CallApi.quickTransfer(phone);
            return false;
        }

        // если уже идет процесс звонка на третьего абонента, то другие кнопки перевода звонка работать не должны
        if (button.hasClass('disabled')) {
            return false;
        }

        let buttons = $('.call-api-transfer');

        // Если уже есть соединение с третьим абонентом
        if (CallApi.transfer_in_process) {

            // Переключаем клиента на третьего абонента
            CallApi.switchCall();

            button.removeClass('active').removeClass('in-process');
            buttons.removeClass('disabled');

        } else { // Если переключение не началось

            buttons.addClass('disabled');
            button.removeClass('disabled');

            // Соединяемся с указанным номером в качестве третьего абонента
            CallApi.transfer(phone, function () {
                button.addClass('in-process');
            });
        }

        return false;
    });

    return CallApi;

}