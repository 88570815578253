import $ from "jquery";

export default class Arr {

    static add(arr, item) {
        arr.push(item);
        return arr;
    }

    static removeItem(arr, item) {
        $.each(arr, function (i, value) {
            if (value === item) {
                arr.splice(i, 1);
            }
        });
        return arr;
    }

}