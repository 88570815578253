import $ from 'jquery';

export default function () {

    $(function () {
        $('a[data-method]').on('click', function () {
            let method = $(this).data('method').toLowerCase();
            let action = $(this).attr('href');

            if (method === 'post') {
                let form = $('<form>', {action: action, method: method});
                $('body').append(form);
                form.submit();
                return false;
            }

            return true;
        });
    });

}