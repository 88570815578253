import $ from 'jquery';

export default class PLayer {

	constructor(audiotag) {
		this.time = 0;
		this.totalTime = 0;
		this.playing = false;
		this.stopped = false;
		this.stopTimer = null;
		this.previousTime = null;
		this.audioTag = audiotag;
		this.player = $('.player');
		this.player_showing = false;
		this.playButton = $('.player-play');

		this.volume = 1;
		this.lastVolume = 1;
		this.volumeIcon = $('.player-volume-icon');
		this.volumeValue = $('.player-volume-value');

		this.init();
	}

	formatTime(a) {
		let b = parseInt(a / 60000);
		let c = parseInt((a % 60000) / 1000);
		if (c < 10) {
			c = "0" + c;
		}
		return b + ":" + c;
	}

	reset() {
		this.time = 0;
		this.audioTag.currentTime = 0;
	}

	togglePlay() {
		if (!this.playing) {
			this.play();
		} else {
			this.pause();
		}
	}

	play() {
		let that = this;

		if (!this.player_showing) {
			this.show();
		}

		if (!this.playing) {
			this.stopped = false;
			this.audioTag.play();
			this.playing = true;
			$('.player-play').removeClass("paused");

			this.stopTimer = setInterval(function () {
				that.updateTimer();
			}, 1000);
		}
	}

	pause() {
		if (this.playing) {
			this.stopped = true;
			this.playing = false;
			this.audioTag.pause();
			clearInterval(this.stopTimer);
			$('.player-play').addClass("paused");
		}
	}

	updateProgress() {
		$('.player-totalTime').html(this.formatTime(this.totalTime));
		$('.player-currentTime').html(this.formatTime(this.time));
		let percent = this.time / this.totalTime * 100;
		if (percent > 100) {
			percent = 100;
		}
		$('.player-progress').css("width", percent + "%");
	}

	rewind5s() {
		if (this.time > 5000) {
			this.time -= 5000;
		} else {
			this.time = 0;
		}
		this.audioTag.currentTime = parseInt(this.time / 1000);
		this.updateProgress();
	}

	forward5s() {
		if ((this.time + 5000) < this.totalTime) {
			this.time += 5000;
		} else {
			this.time = this.totalTime;
		}
		this.audioTag.currentTime = parseInt(this.time / 1000);
		this.updateProgress();
	}

	volumeUp() {
		if (this.volume < 1) {
			this.volume += 0.1;
			this.updateVolume();
		}
	}

	volumeDown() {
		if (this.volume > 0) {
			this.volume -= 0.1
			this.updateVolume();
		}
	}

	toggleVolume() {
		if (this.volume) {
			this.lastVolume = this.volume;
			this.volume = 0;
		} else {
			this.volume = this.lastVolume;
		}
		this.updateVolume();
	}

	updateVolume() {
		if(this.volume > 1 ){
			this.volume = 1.0;
		}
		if(!this.volume  || this.volume < 0){
			this.volume = 0.0;
		}
		this.audioTag.volume = this.volume;
		let percent = parseInt(this.volume * 100);
		this.volumeValue.text(percent + '%');

		if (!percent) {
			this.volumeIcon.addClass('muted');
			this.volumeIcon.removeClass('half');
		} else {
			this.volumeIcon.removeClass('muted');

			if (percent > 50) {
				this.volumeIcon.removeClass('half');
			} else {
				this.volumeIcon.addClass('half');
			}
		}
	}

	updateTimer() {

		if (!this.totalTime) {
			this.totalTime = parseInt(this.audioTag.duration * 1000);
			this.updateProgress();
		}

		//for the end of the song
		if (this.time >= this.totalTime) {
			this.time = this.totalTime;
			if (!this.playing) {
				return;
			}
			this.updateProgress();
			this.pause();
			return;
		}

		//update timer
		if (this.playing) {
			this.time += 1000;
		}

		// Смотрим, изменилось ли время воспроизведения в теге аудио
		if (this.audioTag.currentTime !== this.previousTime) {
			// если да, записываем прежнее значение
			this.previousTime = this.audioTag.currentTime;
			// и обновляем прогрессбар
			this.updateProgress();
		} else {
			// Если время воспроизведения не изменилось

			// Получаем текущее время воспроизведения в секундах
			this.time = parseInt(this.audioTag.currentTime * 1000);

			// Если сейчас идет воспроизведение
			if (this.playing) {
				// ставим на паузу
				this.audioTag.pause();
			}

			// Если у нас прогрузилось нормальное количество данных для воспроизведения
			if (!this.stopped && !this.playing && this.audioTag.readyState === 4) {
				// Включаем дальше
				this.audioTag.play();
			}
		}
	}

	load(file) {
		this.pause();
		this.audioTag.src = file;
		this.totalTime = 0;
		this.updateTimer();
	}

	show() {
		if (!this.player_showing) {
			this.player.show();
			this.player_showing = true;
		}
	}

	hide() {
		if (this.player_showing) {
			this.pause();
			this.player.hide();
			this.player_showing = false;
		}
	}

	init() {

		let that = this;

		this.updateVolume();

		this.playButton.on('click', function () {
			that.togglePlay()
		});

		this.volumeIcon.on('click', function(){
			that.toggleVolume();
		});

		$(document).on('keydown', function (event) {
			if (that.player_showing) {
				switch (event.keyCode) {
					case 27: //escape
						that.hide();
						return false;
					case 32: // space
						that.togglePlay();
						return false;
					case 37: // left
						that.rewind5s();
						return false;
					case 38: // up
						that.volumeUp();
						return false;
					case 39: // right
						that.forward5s();
						return false;
					case 40: // bottom
						that.volumeDown();
						return false;
				}
			}
		});

		$('.player-close').on('click', function () {
			that.hide();
			return false;
		});

		$('.player-progress-bar, .player-progressButton').on('click', function (event) {
			event.preventDefault();
			let width = $('.player-progress-bar').css("width");
			let percent = parseInt(event.offsetX) / parseInt(width) * 100;
			$('.player-progress').css("width", percent + "%");
			that.time = parseInt(that.totalTime * (percent / 100));
			that.audioTag.currentTime = parseInt(that.time / 1000);
		});

	}
}