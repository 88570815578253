import $ from 'jquery';
import EditableField from "./modules/editable-field";
import tabsJs from './modules/tabs';
import GridSearch from "./modules/gridsearch";
import Dropwdown from './modules/dropdown';
import client from './modules/client';
import MethodLinks from "./modules/MethodLinks";
import NotifyBar from "./modules/NotifyBar";
import clickModal from "./modules/clickModal";
import CallApi from "./modules/CallApi";
import wNumb from 'wnumb';
import Vue from 'vue';
import datepick from './vendor/datepicker';
import {Modal} from "./modules/modal";
import Player from './modules/Player';

window.Vue = Vue;

window.$ = window.JQuery = $;

$(document).ready(function () {

	$('.inner-toggle').on('click', function () {
		var id = $(this).data('id');
		var inner_class = '.inner-' + id;
		$(inner_class).toggleClass('active');
		return false;
	});

	let clientName = new EditableField('#client-name-editable', {
		placeholder: 'Введите имя клиента',
		onSave: function (element) {
			let client_id = $(element).data('client_id');
			$.post('/client/' + client_id + '/update/', {
				name: $(element).text().trim()
			}, function () {
				$.notify('Сохранил', 'success');
			});
		}
	});
	clientName.run();

	let clientComment = new EditableField('#client-comment-editable', {
		onSave: function (element) {
			let client_id = $(element).data('client_id');
			$.post('/client/' + client_id + '/update/', {
				comment: $(element).text().trim()
			}, function () {
				$.notify('Сохранил', 'success');
			});
		}
	});
	clientComment.run();

	let clientEmail = new EditableField('#client-email-editable', {
		onSave: function (element) {
			let client_id = $(element).data('client_id');
			$.post('/client/' + client_id + '/update/', {
				email: $(element).text().trim()
			}, function () {
				$.notify('Сохранил', 'success');
			});
		}
	});
	clientEmail.run();

	let clientProjectComments = new EditableField('.client-project-comment', {
		placeholder: 'Комментарий',
		onSave: function (element) {
			let client_id = $(element).data('client_id');
			$.post('/client/' + client_id + '/comment/', {
				project_id: $(element).data('project_id'),
				comment: $(element).text().trim()
			}, function () {
				$.notify('Сохранил', 'success');
			});
		}
	});
	clientProjectComments.run();

	new tabsJs('.tabs-js');

	let gridSearch = new GridSearch({});
	gridSearch.run();

	$('.toggle-filters').on('click', function () {
		$('#filters-search').toggle();
		return false;
	});

	$('.toggle-calls').on('click', function () {
		$('.calls-list-item').toggleClass('hidden');
		return false;
	});

	new Dropwdown('.dropdown');

	client();

	clickModal('.click-modal');

	MethodLinks();

	NotifyBar();

	let intFormat = wNumb({
		mark: '.',
		thousand: ' ',
		prefix: '',
		suffix: ''
	});

	$('.format-number').on('keyup', function () {
		let value = parseInt($(this).val().replace(/[^.\d]+/g, ''));
		if (value) {
			$(this).val(intFormat.to(value));
		}

		return true;
	});

	datepick();

	$('.stat-date').datepicker({
		timepicker: false,
		autoClose: true,
		dateFormat: 'yyyy-mm-dd'
	});

	window.app = CallApi();

	$('#report-status').on('change', function () {
		let value = $(this).val();
		let tableBody = $('#report-rows');
		let rows = tableBody.find('tr');

		if (!value) {
			rows.show();
		} else {
			rows.hide();
			rows.filter(function () {
				return $(this).hasClass('status-' + value);
			}).show();
		}
	});

	$('.status-select').on('click', function () {
		let status_id = $(this).data('status_id');
		$('#report-status').val(status_id).trigger('change');
		$('#calls-list').removeClass('hidden');
		$('html, body').stop().animate({
			scrollTop: $('#list').offset().top
		}, 500);
		return false;
	});

	$('.toggle-visible').on('click', function () {
		$($(this).data('target')).toggleClass('hidden');
		return false;
	});

	$('#client-email-send').on('click', function () {
		let client_id = $(this).data('client_id');
		setTimeout(function () {
			$.get('/client/' + client_id + '/send/', function () {
				$.notify('Отправлено', 'success');
				$.notify('Клиент помечен как выполненный', 'success');
			}).fail(function () {
				$.notify('Ошибка отправки', 'error');
			});
		}, 1000);
		return false;
	});

	$('#client-messenger').on('click', function () {
		let url = $(this).data('href');
		$.get(url, function (response) {
			let modal = new Modal({
				onClose: function () {
					$('#client-messenger-send').off('click');
					return true;
				}
			});
			modal.content(response);
			modal.open();

			$('#client-messenger-send').on('click', function () {
				$.post(url, function () {
					$.notify('Клиент помечен как выполненный', 'success');
					modal.close();
				});
				return false;
			});
		});
		return false;
	});

	let player = new Player(document.getElementById('audioFile'));

	$('.call-play').on('click', function () {
		player.load($(this).attr('href'));
		player.play();
		return false;
	});
});