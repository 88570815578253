import $ from 'jquery';
import Arr from "./Arr";

export default class GridSearch {

    constructor(params) {
        this.formSelector = params.form || '#filters-search';
        this.form = $(this.formSelector);
        this.selector = params.selector || '.table-filters';
        this.resultsSelector = params.results || '.table-results';
        this.resultsContainer = $(this.resultsSelector);
        this.url = params.url || window.location.pathname + 'filters/';
        this.filtersContainer = $(this.selector);
    }

    run() {
        let $this = this;

        this.form.on('submit', function () {
            $this.search();
            return false;
        });

        this.filtersContainer.on('change', 'input,select,textarea', function (e) {
            $this.search(false);
        });

        $('#client-filters').on('click', '.label', function () {
            let item = $(this);
            let field = item.data('field');
            let value = String(item.data('value'));
            let input = $this.form.find('input[name^="advertSearch[' + field + ']"]');

            let isDropdown = input.hasClass('dropdown-input');
            let isTextInput = input.attr('type') === 'text';
            let isCheckbox = input.filter(function(){
                return $(this).attr('type') === 'checkbox';
            }).length > 0;
            let isRadioButton = input.attr('type') === 'radio';

            if (isDropdown) {
                let values = input.val().split(';');
                values = Arr.removeItem(values, value);
                input.val(values.join(';'));
                input.trigger('change');
            } else if (isTextInput) {
                input.val('').trigger('change');
            } else if (isCheckbox) {
                input.filter(function(){
                    return $(this).attr('value') === value;
                }).prop('checked', false).trigger('change');
            } else if(isRadioButton){
                let zeroValue = input.filter(function(){
                    let val = $(this).val();
                    return val === '' || val === '0';
                });
                input.prop('checked', false);
                zeroValue.prop('checked', true).trigger('change');
            }

            return false;
        });
    }

    search(preview = false) {
        let $this = this;
        $.post(this.url, $this.form.serialize(), function (response) {
            if (!preview) {
                $this.resultsContainer.html(response.results);
            }
            $('#client-filters').html(response.filters);
            $('#project-coords').text(response.coords);
            let submit = $this.form.find('button[type="submit"]');
            submit.text('Найдено ' + response.count);
        }, 'json');
    }
}