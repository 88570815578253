import $ from 'jquery';

export default class TabsJs {

    constructor(selector) {
        this.selector = selector;
        this.el = $(selector);
        this.tabs = {};

        this.index();
        this.run();
    }

    index() {
        let $this = this;
        this.el.each(function () {
            let links = $(this).find('.tab-link');
            let index = $(this).index();
            let tabs = [];
            links.each(function () {
                let tabSelector = $(this).attr('href');
                let tabContent = $(tabSelector);
                tabs.push(tabContent);
            });
            let tabObject = {
                links: links,
                tabs: tabs
            };
            $this.tabs[index] = tabObject;
        });
    }

    run() {
        let $this = this;
        this.el.on('click', '.tab-link', function () {
            let parent = $(this).closest($this.selector);
            let index = parent.index();
            let tabs = $this.tabs[index]['tabs'];

            parent.find('.tab-item').removeClass('active');

            $.each(tabs, function () {
                $(this).addClass('hidden');
            });
            var activeTabSelector = $(this).attr('href');
            $(activeTabSelector).removeClass('hidden');
            $(this).closest('.tab-item').addClass('active');
            return false;
        });
    }
}