import $ from 'jquery'

export  class Modal {

    constructor(params = {}) {
        this.onClose = params.onClose || null;
        this.background = $('<div>', {class: 'modal-background'});
        this.modal = $('<div>', {class: 'modal-window'});
        this.opened = false;
    }

    content(body) {
        this.modal.html(body);
    }

    open() {
        this.opened = true;
        this.background.appendTo('body');
        this.modal.appendTo(this.background);

        let $this = this;
        this.background.on('click', function (e) {
            if($(e.target).closest('.modal-window').length){
                return false;
            }
            $this.close();
            return true;
        });
    }

    isOpened() {
        return this.opened;
    }

    close() {
        if(!this.onClose || this.onClose()) {
            this.background.detach();
            this.opened = false;
        }
    }
}