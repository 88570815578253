import $ from 'jquery'
import Arr from "./Arr";

export default class Dropwdown {

    constructor(selector) {
        this.selector = selector;
        this.el = $(this.selector);

        this.init();
    }

    dropdown(el) {
        let object = {};
        object.dropdow = $(el).hasClass('.dropdown') ? $(el) : $(el).closest('.dropdown');
        object.label = object.dropdow.find('.dropdown-items-label');
        object.input = object.dropdow.find('.dropdown-input');
        object.container = object.dropdow.find('.dropdown-items-container');
        object.itemsContainer = object.dropdow.find('.dropdown-items');
        object.items = object.itemsContainer.find('.dropdown-items-item');
        object.selectedItems = object.itemsContainer.find('.dropdown-items-item.active');
        object.multiple = object.itemsContainer.hasClass('multiple');
        object.clearable = object.dropdow.data('clearable');
        object.close = object.dropdow.find('.dropdown-clear');

        return object;
    }

    closeAll() {
        $('.dropdown-items-label').removeClass('active');
        $('.dropdown-items').removeClass('active');
        $('body').off('click.dd.body');
    }

    init() {
        let root = this;

        $('body')
            .on('update.dd.body', this.selector, function () {
                root.updateLabel(this);
            })
            .on('click.dd.label', this.selector + ' .dropdown-items-label', function (e) {
                return root.clickLabel(this, e);
            })
            .on('click.dd.items', this.selector + ' .dropdown-items-item', function () {
                return root.clickItem(this);
            })
            .on('keyup', this.selector + ' .dropdown-search', function () {
                return root.searchItems(this);
            })
            .on('click', this.selector + ' .dropdown-clear', function () {
                return root.clear(this);
            })
            .on('change', this.selector + ' .dropdown-input', function () {
                return root.updateLabel(this);
            })
            .on('click', this.selector + ' .dropdown-items-selected div', function () {
                return root.clearItem(this);
            });

        this.addMisc();
    }

    addMisc() {
        let root = this;

        this.el.each(function () {
            let dropdown = root.dropdown(this);
            if (dropdown.items.length > 20) {
                let search = $('<input>', {type: 'text', class: 'dropdown-search', placeholder: 'Поиск'});
                let searchBox = $('<div>', {class: 'dropdown-search-box'});
                searchBox.append(search);
                dropdown.itemsContainer.find('.dropdown-items-list').prepend(searchBox);
            }

            if (dropdown.clearable) {
                dropdown.container.prepend($('<span>', {class: 'dropdown-clear', title: 'Очистить поле'}));
            }

            root.updateLabel(this);
        });
    }

    clear(el) {
        let dropdown = this.dropdown(el);

        dropdown.input.val('').trigger('change');
        this.updateLabel(el);

        this.closeAll();
    }


    clickLabel(item, e) {

        let root = this;
        let $this = root.dropdown(item);

        if ($this.label.hasClass('active')) {
            root.closeAll();
        } else {
            root.closeAll();

            $this.label.addClass('active');
            $this.itemsContainer.addClass('active');

            let search = $this.itemsContainer.find('.dropdown-search');
            if (search.length) {
                search.focus();
            }

            setTimeout(function () {
                $('body').on('click.dd.body', function (e) {
                    if ($(e.target).closest('.dropdown-items').length !== 0) {
                        return true;
                    }
                    root.closeAll();
                    return true;
                });
            }, 100);
        }

        e.stopPropagation();

        return true;
    }

    searchItems(searchField) {
        let root = this;
        let dropdown = root.dropdown(searchField);

        let name = $(searchField).val().trim().toLowerCase();

        if (name.length) {
            dropdown.items.each(function () {
                let itemLabel = $(this).text().trim().toLowerCase();
                if (itemLabel.indexOf(name) === -1) {
                    $(this).hide();
                } else {
                    $(this).show();
                }
            });
        } else {
            dropdown.items.show();
        }
    }

    clickItem(item) {

        let root = this;
        let clickedItem = $(item);
        let itemValue = clickedItem.data('value').toString();

        let $this = root.dropdown(item);

        // Получаем текущие значения
        let values = $this.input.val().split(';').map(function (item) {
            return item.toString();
        });

        // Есть ли среди выбранных элементов кликнутый
        let hasValue = values.includes(itemValue);

        // Есть ли среди выбранных элементов пустой
        let hasEmpty = values.includes('');

        // Если среди выбранных элементов есть пустой и кликнули не на пустой элемент
        if (hasEmpty && itemValue !== '') {
            // Удаляем из выбранных пустой
            values = Arr.removeItem(values, '');
        }

        // Если среди выбранных нет пустых, а кликнули на пустой
        if (!hasEmpty && itemValue === '') {
            // Удаляем все выбранные элементы
            values = [];
        }

        // если разрешен множественный выбор
        if ($this.multiple) {
            if (hasValue) {
                values = Arr.removeItem(values, itemValue);
            } else {
                values.push(itemValue);
            }
        } else {
            if (hasValue && hasEmpty) {
                values = [];
            } else {
                values = [itemValue];
            }
        }

        $this.input.val(values.join(';')).trigger('change');
    }

    updateLabel(el) {

        let $this = this.dropdown(el);

        // Достаем текущие значения
        let values = $this.input.val().split(';');

        // Убираем статус активности у всех строчек
        $this.items.removeClass('active');

        // Проходимся по значениям и добавляем статус активности для соответствующих строчек
        $.each(values, function (index, element) {
            $this.itemsContainer.find("[data-value='" + element + "']").addClass('active');
        });

        // Достаем текст для лейбла на основе содержимого выбранных строчек
        $this.label.text(this.getLabels($this.items));

        // Если это селект с возможностью выбора нескольких вариантов
        if ($this.multiple) {

        }
        // обычный селект
        else {
            // Закрываем выпадающее меню с вариантами
            $this.itemsContainer.removeClass('active');

            // Убираем статус открытого селекта у лейбла
            $this.label.removeClass('active');

            // Снимаем слушатель клика на body для закрытия открытых селектов
            $('body').off('click.dd.body');
        }

        // Если нет выбранных значений или выбран пустой вариант
        if (!values.length || (values.length === 1 && values.includes(''))) {
            // Добавляем класс "не выбрано"
            $this.label.addClass('unselected');

            // Смотрим наличие пустого варианта среди доступных опций
            let zeroItem = $this.items.filter("[data-value='']");

            // Если он есть
            if (zeroItem.length) {
                // Делаем его активным
                zeroItem.addClass('active');
                $this.label.text(zeroItem.text());
            } else {
                $this.label.text($this.dropdow.data('placeholder'));
            }

            if ($this.clearable) {
                $this.close.removeClass('active');
            }
        }

        // Если же что-то выбрано
        else {

            // убираем у лейбла класс "не выбано"
            $this.label.removeClass('unselected');

            // Достаем список всех выбранных вариантов
            let selectedItems = $this.itemsContainer.find('.dropdown-items-item.active');

            // Смотрим есть ли у них специальный дополнительный класс
            let itemClass = selectedItems.data('class');

            // Убираем у лейбла прежний доп класс
            $this.label.removeClass($this.label.data('item-class')).data('item-class', '');

            // Если есть выбранные опции и у них есть доп класс
            if (selectedItems.length && itemClass) {
                // Добавляем лейблу новый класс
                $this.label.addClass(itemClass).data('item-class', itemClass);
            }

            if ($this.clearable) {
                $this.close.addClass('active');
            }
        }

        let selectedItems = $this.itemsContainer.find('.dropdown-items-item.active');

        let selectedContainer = $this.itemsContainer.find('.dropdown-items-selected');
        if ($this.multiple && selectedItems.length) {
            selectedContainer.html('');
            selectedContainer.show();
            selectedItems.each(function () {
                let item = $('<div>', {
                    "data-value": $(this).data('value'),
                    class: 'label label-primary',
                    title: 'Удалить'
                }).text($(this).text());
                selectedContainer.append(item);
            });
        } else {
            selectedContainer.hide();
        }
    }

    getLabels(items) {

        let dropdown = this.dropdown(items);

        let activeItems = items.filter(function () {
            return $(this).hasClass('active');
        });

        let values = [];
        activeItems.map(function () {
            values.push($(this).text().trim());
        });

        if (dropdown.multiple) {
            return 'Выбрано: ' + values.length;
        }

        return values.join(', ');
    }

    clearItem(item) {
        let selected = $(item);
        let dropdown = this.dropdown(item);
        let values = dropdown.input.val().split(';')
        values = Arr.removeItem(values, String(selected.data('value')));
        dropdown.input.val(values.join(';')).trigger('change');
        return false;
    }

    getValues(items) {
        let activeItems = items.filter(function () {
            return $(this).hasClass('active');
        });

        let values = [];
        activeItems.map(function () {
            values.push($(this).data('value'));
        });

        return values.join(';');
    }
}