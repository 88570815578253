import $ from 'jquery';
import {Modal} from "./modal";

export default function clickModal(selector) {

	let modal = new Modal();

	$('body').on('click', selector, function () {
		if (modal.isOpened()) {
			modal.close();
		} else {
			let contentSelector = $(this).data('modal');
			let content = $(contentSelector).html();
			modal.content(content);
			modal.open();
		}
		return false;
	});
}