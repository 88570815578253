import $ from 'jquery';
import {Modal} from "./modal";
import datepick from '../vendor/datepicker';
import notify from '../vendor/notify.js';
import moment from "moment";

window.$ = window.JQuery = $;

export default function () {

	$(function () {

		datepick();

		$('body').on('change', '.project_status input[type="hidden"]', function () {
			let parent = $(this).closest('.project_status');
			let status = parseInt($(this).val());
			let project_id = parent.data('project_id');
			let client_id = parent.data('client_id');

			$.post('/client/' + client_id + '/status/', {
				status: status,
				project_id: project_id,
			}, function (data) {
				$.notify('Сохранено', 'success');
			}).fail(function(){
				$.notify('Ошибка смены статуса', 'error');
			});

			if (status === 6 || status === 4) {

				let isManualClose = false;

				let modal = new Modal({
					onClose: function(){
						if(isManualClose){
							return true;
						}

						return confirm('Закрыть окно? Напоминание не будет сохранено');
					}

				});
				modal.content(
					'<h4>Новое напоминание</h4>' +
					'<p>' +
					'Перезвонить в <input type="text" class="form-control" id="notify-date" value="">' +
					'</p>' +
					'<div class="text-center margin-mini-top">' +
					'<a class="btn btn-green" href="#" id="add-notify">Добавить</a>' +
					'</div>'
				);
				modal.open();

				let date = moment();
				date.minutes(0);

				if (date.hours() + 3 <= 20) {
					date.hours(date.hours() + 3);
				} else {
					date.add(1, 'd');
					date.hours(12);
				}

				$('#notify-date').val(date.format('YYYY-MM-DD HH:mm'));

				$('#notify-date').datepicker({
					timepicker: true,
					dateFormat: 'yyyy-mm-dd',
					autoClose: false,
					startDate: date.toDate(),
					minutesStep: 30
				});

				$('#add-notify').on('click', function () {
					let date = $('#notify-date').val();
					if (!date) {
						return false;
					}
					$.post('/client/' + client_id + '/notify/', {
						date: date,
						comment: 'Клиент просил перезвонить'
					}, function (data) {
						$.notify('Напоминание добавлено', 'success');
					});
					isManualClose = true;
					modal.close();
					return false;
				});
			}

			if(status === 8 || status === 20){
				if($(this).data('silent')){
					$(this).data('silent', false);
				}else {
					$.notify('Клиент помечен как выполненный', 'success');
				}
			}
		});

		$('body').on('click', '.bounce_all', function () {
			let client_id = $(this).data('client_id');
			$.post('/client/' + client_id + '/bounce/', function(){
				$.notify('Клиент помечен как выполненный', 'success');
			});
			$('.project_status').find('.dropdown-input').val(10).data('silent',true).trigger('change');
			return false;
		});


		let mapLoaded = false;

		if(typeof mapScript !== 'undefined' && mapScript === true) {
			ymaps.ready(function () {
				mapLoaded = true;
			});
		}

		let map = null;

			$('.projects-map').on('click', function () {
				let coords = JSON.parse($('#project-coords').text());

				if (mapLoaded) {
					let modal = new Modal({
						onClose: function () {
							if (map) {
								map.destroy();
							}
							return true;
						}
					});

					let mapContainer = $('<div>', {id: 'map', class: 'client-map'});
					modal.content(mapContainer);
					modal.open();

					map = new ymaps.Map("map", {
						center: [55.76, 37.64],
						zoom: 12
					});

					map.events.add('click', function () {
						map.balloon.close();
					});

					$.each(coords, function (index, val) {
						let pm = new ymaps.Placemark(val.coords, {
							iconContent: val.project,
							balloonMaxheight: 200,
							balloonContentBody: val.prices
						}, {
							preset: 'islands#redStretchyIcon',
							balloonCloseButton: false
						});

						map.geoObjects.add(pm);
					});
					map.setBounds(map.geoObjects.getBounds());

				}

				return false;
			});

	});

}