import $ from 'jquery';

class EditableField {

    constructor(selector, options) {
        this.selector = selector;
        this.el = $(selector);
        this.fieldName = options.name || this.el.data('name');
        this.placeholder = options.placeholder || this.el.data('placeholder') || 'Введите значение';
        this.sourceValue = this.el.length ? this.el.html().trim() : '';
        this.onSave = options.onSave || function () {
        };
    }

    applyPlaceholder(el) {
        if (!el.sourceValue) {
            el.text(this.placeholder).addClass('editable-placeholder');
        }
    }

    run() {
        let $this = this;

        this.el.attr('title', 'Редактировать');

        this.el.each(function(){
            let field = $(this);
            field.sourceValue = field.html();
            $this.applyPlaceholder(field);
        });

        $('body').on('click', $this.selector, function(){
            let field = $(this);

            if (field.attr('contentEditable') === true) {
                return false;
            }

            if (field.hasClass('editable-placeholder')) {
                field.text('').removeClass('editable-placeholder');
            }

            field.attr('contentEditable', true);
            field.focus();

            field.on('keydown', function (e) {
                let keyCode = Number(e.which || e.keyCode || e.key);

                // Enter - save entered value
                if (keyCode === 13) {
                    $this.save(field);
                    return false;
                }

                // Escape - exit without saving
                if (keyCode === 27) {
                    $this.cancel(field);
                }
            }).on('blur', function () {
                $this.save(field);
            });

            return false;
        });
    }

    cancel(el) {
        el.attr('contentEditable', false);
        el.html(this.sourceValue);
        this.applyPlaceholder(el);
    }

    save(el) {
        if (el.attr('contentEditable') === 'true') {
            el.attr('contentEditable', false);
            el.sourceValue = el.html().trim();
            this.onSave.apply(this, el);
            this.applyPlaceholder(el);
        }
    }
}

export default EditableField;