import $ from 'jquery';

export default function () {

    $(function () {
        $('.notify-icon').on('click', function () {
            $(this).toggleClass('active');
            let box = $(this).closest('.notify-bar-box');
            box.toggleClass('active');

            if(box.hasClass('active')){
                $('body').on('click.notify-bar', function(e){
                    if ($(e.target).closest('.notify-bar-box').length > 0) {
                        return true;
                    }
                    $('.notify-bar-box').removeClass('active');
                    $('body').off('click.notify-bar');
                    return true;
                });
            }
            return false;
        });

        $('.notify .close').on('click', function () {
            let notify_id = $(this).data('id');
            let client_id = $(this).data('client_id');
            $.post('/client/' + client_id + '/notify/done/', {notify_id: notify_id}, function () {
                $('.notify-item-' + notify_id).fadeOut(500, function () {
                    $(this).remove();
                    checkNotify();
                });
            });

            return false;
        });

        function checkNotify() {
            let sidebar = $('.notify-bar-box');
            let container = $(sidebar).find('.notify-bar');
            let badge = sidebar.find('.notify-icon-badge');
            let length = sidebar.find('.notify').length;

            badge.text(length);

            if(!length){
                badge.hide();
                container.text('Ура! Уведомлений больше нет!');
            }

            let clientNotify = $('#client-notify');
            if (!clientNotify.find('.notify').length) {
                clientNotify.remove();
            }
        }
    });

}